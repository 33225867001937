/* src/components/Footer.css */

.footer {
  color: white;
  text-align: center;
  padding: 20px 0;
  width: 100%;
}

.footer-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.footer-icon {
  width: 40px;
  height: auto;
  margin: 0 10px;
}

.footer-text {
  font-size: 1em;
  font-weight: bold;
}