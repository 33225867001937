/* src/index.css */

@font-face {
  font-family: 'Rubik One';
  src: url('../public/fonts/RubikOne-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  padding: 0;
}