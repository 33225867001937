/* src/components/Container.css */

.bg {
  margin: 0;
  padding: 0;
  background-color: #4DA2FF;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  min-height: 100vh;
  min-width: 1200px;
}

.outer-container {
  background-color: #258FF1;
  width: 90%;
  max-width: 1300px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  min-height: 100vh;
  min-width: 1000px;
}

.inner-container1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  width: 100%;
  /* min-width: 1350px; */
  /* height: 500px; */
  padding: 0px;
  margin: 0px;
}

.inner-container2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-width: 1350px;
  height: 100%;
  background-color: white;
  padding: 0px;
  margin: 0px;
}

.content-container1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  width: 100%;
  /* min-width: 1350px; */
  padding: 0px;
  margin: 0px;
  margin-top: 100px;
  overflow: hidden;
}

.text-container {
  margin-top: 50px;
  width: 36%;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 4;
}

.text-headline {
  font-family: 'Lilita One';
  font-style: normal;
  font-weight: 400;
  font-size: 50px;
  line-height: 57.15px;
  color: #FFFFFF;
}

.text-content {
  width: 100%;
  font-family: 'Lilita One';
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 34.25px;
  color: #FFFFFF;
  text-align: left;
}

.images {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 20px;
  height: 320px;
  width: 100%;
}

.waves {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 250px;
  width: 100%;
  overflow: hidden;

}

.wave {
  width: 120% !important;
  height: auto !important;
}

.image1 {
  margin-top: 400px;
  width: 300px;
  height: auto;
}

.image2 {
  width: 375px;
  height: auto;
}

.image3 {
  margin-top: 400px;
  width: 310px;
  height: auto;
}

.headline {
  /* position: absolute; */
  width: 50%;
  z-index: 4;
  font-family: 'Rubik One';
  font-style: normal;
  font-weight: 400;
  font-size: 100px;
  line-height: 123.8px;
  text-align: center;
  color: #FFFFFF;
}

.sub-headline {
  width: 80%;
  margin-top: 40px;
  z-index: 4;
  font-family: 'Lilita One';
  font-style: normal;
  font-weight: 400;
  font-size: 60px;
  line-height: 68.58px;
  text-align: center;
  color: #FFFFFF;
}

.sub-headline-description {
  width: 85%;
  margin-top: 40px;
  z-index: 4;
  font-family: 'Lilita One';
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 30.29px;
  text-align: left;
  color: #FFFFFF;
}

.section {
  background-color: #f0f0f0;
  margin: 10px 0;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.address_shape {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 82%;
  height: 82px;
  border-radius: 60px;
  border: 15px solid #4DA2FF;
  margin-top: 250px;
}


.address {
  font-family: 'Lilita One';
  font-weight: 400;
  font-size: 1.8vw;
  line-height: 34.29px;
  color: #FFFFFF;
}

.liquoromics {
  margin-top: 50px;
  font-family: 'Lilita One';
  font-size: 60px;
  font-weight: 400;
  line-height: 68.58px;
  letter-spacing: 0.02em;
  color: #FFFFFF;
  text-shadow: -1px 5px 0px #000000;

}

.cards {
  display: flex;
  justify-content: space-around;
  width: 95%;
}

.card {
  margin-top: 80px;
  background-color: rgba(77, 162, 255, 1);
  border-radius: 30px;
  padding: 0px;
  text-align: center;
  width: 22%;
  height: 350px;
  display: flex;
  border: 10px solid #FFFFFF;

  flex-direction: column;
  align-items: center;
}

.card-image-holder {
  width: 100%;
  height: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;

}

.card img {
  width: 200px;
  height: auto;
}

.card-text {
  margin-top: auto;
  margin-bottom: 0;
  background: rgba(37, 143, 241, 1);
  width: 90%;
  padding: 10px;
  border-radius: 15px;
  font-family: Lilita One;
  font-size: 20px;
  font-weight: 400;
  line-height: 22.86px;
  text-align: center;
  color: #FFFFFF;
}

.to-the-moon {
  font-family: 'Lilita One';
  font-size: 50px;
  font-weight: 400;
  line-height: 57.15px;
  color: #FFFFFF;
  transform: translate(60%, -800%) rotate(-33.6deg);
}